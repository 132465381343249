.color-picker {
    text-align: center;
    margin-top: 20px;
  }
  
  .color-display {
    width: 100px;
    height: 100px;
    margin: 20px auto;
    border: 1px solid #000;
  }
  