.overlay-box {
    position: relative;
}
.overlay-box{
    /* content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; */
    background: linear-gradient(305deg, rgb(255, 0, 0), rgb(148, 74, 109), rgb(41, 147, 219), rgb(-66, 221, 328), rgb(4, 196, 208), rgb(1, 231, 136), rgb(-1, 267, 65), rgb(36, 249, 76), rgb(146, 231, 38), rgb(255, 213, 0));
    /* z-index: 1; */
    border-radius: 10px;
}
